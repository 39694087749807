import { error } from "jquery";
import {
  USERS_LIST_FAILED,
  USERS_LIST_LOADING,
  USERS_LIST_SUCCESS,
  ADD_CONTACT_FAILED,
  ADD_CONTACT_LOADING,
  ADD_CONTACT_SUCCESS,
  INSERT_CONTACT_LOADING,
  INSERT_CONTACT_SUCCESS,
  INSERT_CONTACT_FAILED,
  USER_UPLOAD_LOADING,
  USER_UPLOAD_SUCCESS,
  USER_UPLOAD_FAILED
} from "./constants";

const initialState = {
  lists: [],
  totalCustomer:null,
  loading:false,
  error:false,
  error: "",
  isUpload:false,
  isUploadError:false
};

export const usersListReducer = (state = initialState, action) => {
  switch (action.type) {
    case USERS_LIST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case USERS_LIST_SUCCESS:
      return {
        loading: false,
        lists: action.payload,
      };
    case USERS_LIST_FAILED:
      return {
        loading: false,
        error: action.payload,
        csvError: action.payload,
      };
    case ADD_CONTACT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ADD_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        data: action.payload,
      };
    case ADD_CONTACT_FAILED:
      return {
        loading: false,
        errMsg: action.payload,
      };
      case INSERT_CONTACT_LOADING:
        return {
          ...state,
          insertLoader: true,
        };
      case INSERT_CONTACT_SUCCESS:
        return {
          ...state,
          insertLoader:false,
          totalProcessedCustomer: action.payload.totalProcessed,
          totalNewCustomer: action.payload.newContacts,
        };
      case INSERT_CONTACT_FAILED:
        return {
          ...state,
          insertLoader:false,
          error: action.payload,
        };
        case USER_UPLOAD_LOADING:{
          return {
            ...state,
            isUpload:true,
            isUploadError:false
          }
        }
        case USER_UPLOAD_SUCCESS:{
          return {
            ...state,
            isUpload:false,
            isUploadError:false
          }
        }
        case USER_UPLOAD_FAILED:{
          return {
            ...state,
            isUpload:false,
            isUploadError:true
          }
        }
    default:
      return state;
  }
};
