import {
  SAVE_FORM_INFO,
  ADD_NEW_CAMPAIGN_LOAD,
  ADD_NEW_CAMPAIGN_SUCCESS,
  ADD_NEW_CAMPAIGN_ERROR,
  GET_ALL_CAMPAIGN,
  GET_ALL_GENRE,
  DELETE_CAMPAIGN,
  UPDATE_CAMPAIGN,
  EMAIL_COMPAIGN_TEST_LOADING,
  EMAIL_COMPAIGN_TEST_FAILURE,
  EMAIL_COMPAIGN_TEST_SUCCESS,
  EMAIL_COMPAIGN_ERROR,
  EMAIL_COMPAIGN_RESET,
  EMAIL_CAMPAIGN_DUPLICATE_SUCCESS,
  EMAIL_CAMPAIGN_DUPLICATE_LOADING,
  GET_ALL_MOVIES,
} from "./constants";

// **  Initial State
const initialState = {
  formData: {},
  rawData: {},
  campaignList: [],
  moviesList: [],
  genreList: [],
  loading: false,
  error: false,
  testLoading: false,
  testSuccess: true,
};

const emailCampaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CAMPAIGN:
      return {
        ...state,
        campaignList: [...action.campaignList],
      };
    case ADD_NEW_CAMPAIGN_LOAD:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ADD_NEW_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        campaignList: [action.campaign,...state.campaignList],
      };
    case ADD_NEW_CAMPAIGN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.msg,
      };

    case DELETE_CAMPAIGN:
      return {
        ...state,
        campaignList: state.campaignList.filter(
          (el) => el.id !== action.payload
        ),
      };
    case SAVE_FORM_INFO:
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.formData,
        },
        rawData: {
          ...state.rawData,
          ...action.rawData,
        },
      };
    case UPDATE_CAMPAIGN: {
      return {
        ...state,
        campaignList: state.campaignList.map((ele) => {
          if (ele.id === action.payload.id) {
            return { ...action.payload };
          } else return ele;
        }),
      };
    }

    case EMAIL_CAMPAIGN_DUPLICATE_LOADING:
      return {
        ...state,
        loading: true,
      };

    case EMAIL_CAMPAIGN_DUPLICATE_SUCCESS:
      return {
        ...state,
        loading: false,
        campaignList: [...state.campaignList, action.template],
      };

    case EMAIL_COMPAIGN_RESET:
      return {
        ...state,
        formData: {},
        rawData: {}
      };

    case EMAIL_COMPAIGN_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case EMAIL_COMPAIGN_TEST_LOADING:
      return {
        ...state,
        testSuccess: false,
        testLoading: true,
      };

    case EMAIL_COMPAIGN_TEST_FAILURE:
      return {
        ...state,
        testLoading: false,
        testSuccess: false,
      };

    case EMAIL_COMPAIGN_TEST_SUCCESS:
      return {
        ...state,
        testLoading: false,
        testSuccess: true,
      };

    case GET_ALL_MOVIES:
      return {
        ...state,
        moviesList: [...action.moviesList],
      };

    case GET_ALL_GENRE:
      return {
        ...state,
        genreList: [...action.genreList],
      };

    default:
      return state;
  }
};

export default emailCampaignReducer;
