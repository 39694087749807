import {
  GET_ALL_SEGMENTS_LOADING,
  GET_ALL_SEGMENTS_ERROR,
  GET_ALL_SEGMENTS_SUCCESS,
  UPDATE_SEGMENTS_LOADING,
  UPDATE_SEGMENTS_ERROR,
  UPDATE_SEGMENTS_SUCCESS,
  DELETE_SEGMENT,
  ADD_NEW_SEGMENT,
  UPDATE_SEGMENT,
  RESET_ADD_SEGMENT,
  GET_SEGMENT_BY_ID,
  RESET_SEGMENT_DATA,
  DELETE_SEGMENT_RESET,
  RESET_ADD_UPDATE_SEGMENT,
  GET_SEGMENT_BY_CUSTOMER_LOADING,
  GET_SEGMENT_BY_CUSTOMER_SUCCESS,
  GET_SEGMENT_BY_CUSTOMER_ERROR,
  UPDATE_SEGMENT_BY_CUSTOMER_LOADING,
  UPDATE_SEGMENT_BY_CUSTOMER_SUCCESS,
  UPDATE_SEGMENT_CUSTOMER,
  UPDATE_SEAT_TYPE,
  UPDATE_TICKET_TYPE,
  UPDATE_BOOKING_SOURCE,
  UPDATE_PAYMENT_MODE,
  UPDATE_MOVIE_GENRE,
  UPDATE_MOVIE_EXPERIENCE,
  UPADATE_CINEMA,
  UPDATE_MOVIE_NAME,
  UPDATE_MOVIE_FORMAT,
  UPDATE_MOVIE_LANGUAGE,
  UPDATE_MOVIE_RATING,
  UPDATE_FROM_DATE,
  UPDATE_TO_DATE,
  UPDATE_FOOD_CATGEORY,
  UDPATE_FNB_ITEMS,
  UPDATE_CUSTOMER_DOB_FROM_DATE,
  UPDATE_CUSTOMER_DOB_TO_DATE,
  UPDATE_CUSTOMER_GENDER,
  UPDATE_CUSTOMER_TYPE,
  UPDATE_PERIOD_COUNT,
  UPDATE_PERIOD_TYPE,
  RESET_FILTERS,
  SUBMIT_FAIL,
  SUBMIT_LOADE,
  SUBMIT_SUCCESS,
  GET_CUSTOMER_LIST_FAIL,
  GET_CUSTOMER_LIST_LOADING,
  GET_CUSTOMER_LIST_SUCCESS,
  GET_CUSTOMER_LIST_RESET,
  FETCH_LOADER_START,
  FETCH_LOADER_STOP,
  RESET_DATE_MODULE,
  RESET_TRANSACTION_MODULE,
  RESET_MOVIE_MODULE,
  RESET_FNB_MODULE,
  RESET_CUSTOMER_MODULE,
  RESET_PERIOD_MODULE,
  UPDATE_CUSTOMER_DOB_MONTH,
  UPDATE_DOB_TYPE_VALUE,
  UPDATE_DOB_TYPE,
  UPDATE_IMPORTED_CUST_DOB_MONTHS,
  UPDATE_IMPORTED_CUST_GENDER,
  UPDATE_IMPORTED_CUST_DOB_TYPE_VALUE,
  UPDATE_IMPORTED_CUST_DOB_TYPE,
  RESET_IMPORTED_CUSTOMER_MODULE,
  RESET_TABLE_DATA,
} from "./constants";

// **  Initial State
const initialState = {
  segmentList: [],
  selectedSegment: {},
  loading: false,
  submitError: false,
  segmentErr: false,
  filters: {
    seat_type_id: "",
    ticket_type: "",
    booking_source_id: "",
    payment_mode_id: "",
    cinema_id: "",
    genre_id: "",
    movie_experience_id: "",
    md_id: "",
    movie_id: "",
    format: "",
    language: "",
    rating: "",
    from_date: null,
    to_date: null,
    fnb_category: "",
    fnb_items: "",
    cust_dob_from_date: null,
    cust_dob_to_date: null,
    cust_dob_month: "",
    cust_dob_type: null,
    cust_dob_type_value: null,
    customer_gender: "",
    imported_cust_dob_month: "",
    imported_cust_dob_type: null,
    imported_cust_dob_type_value: null,
    imported_customer_gender: "",
    custome_type: "",
    period_type: null,
  },
  customerList: [],
  guestCustomerIds: [],
  registerCustomerIds: [],

  totalCustomer: null,
  fetchLoader: false,
  fetchError: false,
};

const segmentReducer = (state = initialState, action) => {
  console.log("action", action);
  switch (action.type) {
    case FETCH_LOADER_START:
      return {
        ...state,
        fetchLoader: true,
      };
    case FETCH_LOADER_STOP:
      return {
        ...state,
        fetchLoader: false,
      };
    case GET_ALL_SEGMENTS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_SEGMENTS_SUCCESS:
      return {
        ...state,
        segmentList: action.segmentData,
        loading: false,
      };
    case GET_ALL_SEGMENTS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case ADD_NEW_SEGMENT:
      return {
        ...state,
        success: true,
      };
    case UPDATE_SEGMENTS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_SEGMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedSegment: {},
        filters: {
          seat_type_id: "",
          ticket_type: "",
          booking_source_id: "",
          payment_mode_id: "",
          cinema_id: "",
          genre_id: "",
          movie_experience_id: "",
          md_id: "",
          movie_id: "",
          format: "",
          language: "",
          rating: "",
          from_date: null,
          to_date: null,
          fnb_category: "",
          fnb_items: "",
          cust_dob_from_date: null,
          cust_dob_to_date: null,
          cust_dob_month: "",
          cust_dob_type: null,
          cust_dob_type_value: null,
          customer_gender: "",
          customer_type: "",
          imported_cust_dob_month: "",
          imported_cust_dob_type: null,
          imported_cust_dob_type_value: null,
          imported_customer_gender: "",
        },
      };
    case UPDATE_SEGMENTS_ERROR:
      return {
        loading: false,
        submitError: true,
      };
    case UPDATE_SEGMENT_CUSTOMER:
      return {
        ...state,
        success: true,
      };
    case RESET_ADD_SEGMENT:
      return {
        ...state,
        success: false,
      };
    case DELETE_SEGMENT:
      return {
        ...state,
        segmentList: state.segmentList.filter((el) => el.id !== action.payload),
      };
    case GET_SEGMENT_BY_CUSTOMER_LOADING:
      return {
        ...state,
        segmentByCustomerLoading: true,
      };
    case GET_SEGMENT_BY_CUSTOMER_SUCCESS:
      return {
        ...state,
        segmentByCustomerLoading: false,
        segmentByCustomerdata: action.payload,
      };
    case GET_SEGMENT_BY_CUSTOMER_ERROR:
      return {
        ...state,
        segmentByCustomerLoading: false,
        segmentByCustomerError: action.payload,
      };
    case UPDATE_SEGMENT_BY_CUSTOMER_LOADING:
      return {
        ...state,
        updateSegmentByCustomerLoading: true,
      };
    case UPDATE_SEGMENT_BY_CUSTOMER_SUCCESS:
      return {
        ...state,
        updateSegmentByCustomerLoading: false,
        updateSuccess: true,
      };
    case UPDATE_SEGMENT:
      return {
        ...state,
        // success: true,
      };
    case GET_SEGMENT_BY_ID:
      return {
        ...state,
        selectedSegment: action.segmentData,
        filters: {
          seat_type_id: action.segmentData.seat_type_id || "",
          ticket_type: action.segmentData.ticket_type_name || "",
          booking_source_id: action.segmentData.booking_source_id || "",
          payment_mode_id: action.segmentData.payment_mode_id || "",
          cinema_id: action.segmentData.cinema_id || "",
          movie_experience_id: action.segmentData.movie_experience_id || "",
          genre_id: action.segmentData.genre_id || "",
          md_id: action.segmentData.md_id || "",
          movie_id: action.segmentData.movie_id || "",
          format: action.segmentData.movie_format_id || "",
          language: action.segmentData.movie_language_id || "",
          rating: action.segmentData.movie_ratings || "",
          from_date: action.segmentData.from_date || null,
          to_date: action.segmentData.to_date || null,
          fnb_category: action.segmentData.fnb_category_id || "",
          fnb_items: action.segmentData.fnb_item_id || "",
          cust_dob_from_date: action.segmentData.c_dob_from_date || null,
          cust_dob_to_date: action.segmentData.c_dob_to_date || null,
          cust_dob_month: action.segmentData.c_dob_months || "",
          cust_dob_type: action.segmentData.c_dob_type || null,
          cust_dob_type_value: action.segmentData.c_dob_type_value || null,
          customer_gender: action.segmentData.c_gender,
          imported_cust_dob_month: action.segmentData.imported_c_dob_months || "",
          imported_cust_dob_type: action.segmentData.imported_c_dob_type || null,
          imported_cust_dob_type_value: action.segmentData.imported_c_dob_type_value || null,
          imported_customer_gender: action.segmentData.imported_c_gender,
          period_type: action.segmentData.period_type,
          period_count: action.segmentData.period_count,
          customer_type: action.segmentData.c_type,
        },
      };
    case RESET_SEGMENT_DATA:
      return {
        ...state,
        selectedSegment: {},
      };
    case UPDATE_SEAT_TYPE:
      return {
        ...state,
        filters: { ...state.filters, seat_type_id: action.payload },
      };
    case UPDATE_TICKET_TYPE:
      return {
        ...state,
        filters: { ...state.filters, ticket_type: action.payload },
      };
    case UPDATE_BOOKING_SOURCE:
      return {
        ...state,
        filters: { ...state.filters, booking_source_id: action.payload },
      };
    case UPDATE_PAYMENT_MODE:
      return {
        ...state,
        filters: { ...state.filters, payment_mode_id: action.payload },
      };
    case UPDATE_MOVIE_EXPERIENCE:
      return {
        ...state,
        filters: { ...state.filters, movie_experience_id: action.payload },
      };
    case UPADATE_CINEMA:
      return {
        ...state,
        filters: { ...state.filters, cinema_id: action.payload },
      };
    case UPDATE_MOVIE_GENRE:
      return {
        ...state,
        filters: { ...state.filters, genre_id: action.payload },
      };
    case UPDATE_MOVIE_NAME:
      return {
        ...state,
        filters: { ...state.filters, movie_id: action.payload },
      };
    case UPDATE_MOVIE_FORMAT:
      return {
        ...state,
        filters: { ...state.filters, format: action.payload },
      };
    case UPDATE_MOVIE_LANGUAGE:
      return {
        ...state,
        filters: { ...state.filters, language: action.payload },
      };
    case UPDATE_MOVIE_RATING:
      return {
        ...state,
        filters: { ...state.filters, rating: action.payload },
      };
    case UPDATE_FROM_DATE:
      return {
        ...state,
        filters: { ...state.filters, from_date: action.payload },
      };
    case UPDATE_TO_DATE:
      return {
        ...state,
        filters: { ...state.filters, to_date: action.payload },
      };
    case UPDATE_FOOD_CATGEORY:
      return {
        ...state,
        filters: { ...state.filters, fnb_category: action.payload },
      };
    case UDPATE_FNB_ITEMS:
      return {
        ...state,
        filters: { ...state.filters, fnb_items: action.payload },
      };

    case UPDATE_CUSTOMER_DOB_FROM_DATE:
      return {
        ...state,
        filters: { ...state.filters, cust_dob_from_date: action.payload },
      };

    case UPDATE_CUSTOMER_DOB_TO_DATE:
      return {
        ...state,
        filters: { ...state.filters, cust_dob_to_date: action.payload },
      };
    case UPDATE_CUSTOMER_DOB_MONTH:
      return {
        ...state,
        filters: { ...state.filters, cust_dob_month: action.payload },
      };
    case UPDATE_DOB_TYPE:
      return {
        ...state,
        filters: { ...state.filters, cust_dob_type: action.payload },
      };
    case UPDATE_DOB_TYPE_VALUE:
      return {
        ...state,
        filters: { ...state.filters, cust_dob_type_value: action.payload },
      };
    case UPDATE_CUSTOMER_GENDER:
      return {
        ...state,
        filters: { ...state.filters, customer_gender: action.payload },
      };
    case UPDATE_CUSTOMER_TYPE:
      return {
        ...state,
        filters: { ...state.filters, customer_type: action.payload },
      };
    case UPDATE_IMPORTED_CUST_DOB_MONTHS:
      return {
        ...state,
        filters: { ...state.filters, imported_cust_dob_month: action.payload },
      };
    case UPDATE_IMPORTED_CUST_DOB_TYPE:
      return {
        ...state,
        filters: { ...state.filters, imported_cust_dob_type: action.payload },
      };
    case UPDATE_IMPORTED_CUST_DOB_TYPE_VALUE:
      return {
        ...state,
        filters: {
          ...state.filters,
          imported_cust_dob_type_value: action.payload,
        },
      };
    case UPDATE_IMPORTED_CUST_GENDER:
      return {
        ...state,
        filters: { ...state.filters, imported_customer_gender: action.payload },
      };
    case RESET_IMPORTED_CUSTOMER_MODULE:
      return {
        ...state,
        filters: {
          ...state.filters,
          imported_cust_dob_month: "",
          imported_cust_dob_type: null,
          imported_cust_dob_type_value: null,
          imported_customer_gender: "",
        },
      };

    case UPDATE_PERIOD_COUNT:
      return {
        ...state,
        filters: { ...state.filters, period_count: action.payload },
      };

    case UPDATE_PERIOD_TYPE:
      return {
        ...state,
        filters: { ...state.filters, period_type: action.payload },
      };
    case RESET_FILTERS:
      return {
        ...state,
        filters: {
          seat_type_id: "",
          ticket_type: "",
          booking_source_id: "",
          payment_mode_id: "",
          md_id: "",
          format: "",
          language: "",
          rating: "",
          from_date: null,
          to_date: null,
          fnb_category: "",
          fnb_items: "",
          cust_dob_from_date: null,
          cust_dob_to_date: null,
          cust_dob_month: "",
          cust_dob_type: null,
          cust_dob_type_value: null,
          customer_gender: "",
          imported_cust_dob_month: "",
          imported_cust_dob_type: null,
          imported_cust_dob_type_value: null,
          imported_customer_gender: "",
          period_count: null,
          period_type: null,
          customer_type: "",
          genre_id: "",
          cinema_id: "",
          movie_experience_id: "",
          movie_id: "",
        },
        customerList: [],
        totalCustomer: null,
        submitError: false,
      };
    case GET_CUSTOMER_LIST_LOADING:
      return {
        ...state,
        fetchLoader: true,
        fetchError: false,
      };

    case GET_CUSTOMER_LIST_SUCCESS:
      return {
        ...state,
        totalCustomer: action.payload.totalCustomer,
        customerList: action.payload.customerList,
        fetchLoader: false,
        fetchError: false,
      };
      case RESET_TABLE_DATA:
        return {
          ...state,
          totalCustomer: action.payload.totalCustomer,
          customerList: action.payload.customerList,
          fetchLoader: false,
          fetchError: false,
        };

    case GET_CUSTOMER_LIST_FAIL:
      return {
        ...state,
        customerList: [],
        registerCustomerIds: [],
        guestCustomerIds: [],
        totalCustomer: 0,
        fetchLoader: false,
        fetchError: true,
      };
    case GET_CUSTOMER_LIST_RESET:
      return {
        ...state,
        customerList: [],
        registerCustomerIds: [],
        guestCustomerIds: [],
        totalCustomer: 0,
        fetchLoader: false,
        fetchError: true,
      };

    case SUBMIT_LOADE:
      return {
        ...state,
        loading: true,
        submitError: false,
      };
    case SUBMIT_SUCCESS:
      return {
        ...state,
        loading: false,
        submitError: false,
      };
    case SUBMIT_FAIL:
      return {
        ...state,
        loading: false,
        submitError: true,
      };
    case RESET_DATE_MODULE:
      return {
        ...state,
        filters: { ...state.filters, from_date: null, to_date: null },
      };
    case RESET_TRANSACTION_MODULE:
      return {
        ...state,
        filters: {
          ...state.filters,
          seat_type_id: "",
          ticket_type: "",
          booking_source_id: "",
          payment_mode_id: "",
          cinema_id: "",
          movie_experience_id: "",
        },
      };
    case RESET_MOVIE_MODULE:
      return {
        ...state,
        filters: {
          ...state.filters,
          movie_id: "",
          format: "",
          language: "",
          rating: "",
          genre_id: "",
        },
      };
    case RESET_FNB_MODULE:
      return {
        ...state,
        filters: { ...state.filters, fnb_category: "", fnb_items: "" },
      };
    case RESET_CUSTOMER_MODULE:
      return {
        ...state,
        filters: {
          ...state.filters,
          cust_dob_from_date: null,
          cust_dob_to_date: null,
          customer_gender: "",
          customer_type: "",
          cust_dob_month: "",
          cust_dob_type: null,
          cust_dob_type_value: null,
        },
      };
    case RESET_PERIOD_MODULE:
      return {
        ...state,
        filters: { ...state.filters, period_type: null, period_count: null },
      };
    default:
      return state;
  }
};

export default segmentReducer;
